import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import StarIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/core/styles";
import Amplify, { Auth } from "aws-amplify";
import aws_exports from "../aws-exports.js";
import companyLogo from "../components/logo.png";
Amplify.configure(aws_exports);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mooommaternidadonline.com/">
        Moom
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
}));

const tiers = [
  {
    title: "Free",
    price: "0",
    description: [
      "10 users included",
      "2 GB of storage",
      "Help center access",
      "Email support",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
  },
  {
    title: "Pro",
    subheader: "Most popular",
    price: "15",
    description: [
      "20 users included",
      "10 GB of storage",
      "Help center access",
      "Priority email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    title: "Enterprise",
    price: "30",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
];

const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Features",
    description: [
      "Cool stuff",
      "Random feature",
      "Team feature",
      "Developer stuff",
      "Another one",
    ],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

const TermsAndConditions = () => {
  const classes = useStyles();
  return (
    <div className="App">
      {" "}
      {/* create component for navigation/app bar*/}
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.toolbarTitle}
          >
            <img
              src={companyLogo}
              style={{ maxWidth: "50px" }}
              alt="company logo"
            />
          </Typography>
          <nav>
            <Link
              variant="button"
              color="textPrimary"
              href="http://m.me/moommaternidadonline"
              className={classes.link}
            >
              Contacto
            </Link>
          </nav>
          <Button
            color="primary"
            variant="outlined"
            className={classes.link}
            onClick={() => Auth.federatedSignIn()}
          >
            Entrar
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Términos y condiciones
        </Typography>
        <Typography
          variant="p"
          align="center"
          color="textSecondary"
          component="p"
        >
          Términos y Condiciones de Uso INFORMACIÓN RELEVANTE Es requisito
          necesario para la adquisición de los productos que se ofrecen en este
          sitio, que lea y acepte los siguientes Términos y Condiciones que a
          continuación se redactan. El uso de nuestros servicios así como la
          compra de nuestros productos implicará que usted ha leído y aceptado
          los Términos y Condiciones de Uso en el presente documento. Todas los
          productos que son ofrecidos por nuestro sitio web pudieran ser
          creadas, cobradas, enviadas o presentadas por una página web tercera y
          en tal caso estarían sujetas a sus propios Términos y Condiciones. En
          algunos casos, para adquirir un producto, será necesario el registro
          por parte del usuario, con ingreso de datos personales fidedignos y
          definición de una contraseña. El usuario puede elegir y cambiar la
          clave para su acceso de administración de la cuenta en cualquier
          momento, en caso de que se haya registrado y que sea necesario para la
          compra de alguno de nuestros productos. moommaternidadonline.com no
          asume la responsabilidad en caso de que entregue dicha clave a
          terceros. Todas las compras y transacciones que se lleven a cabo por
          medio de este sitio web, están sujetas a un proceso de confirmación y
          verificación, el cual podría incluir la verificación del stock y
          disponibilidad de producto, validación de la forma de pago, validación
          de la factura (en caso de existir) y el cumplimiento de las
          condiciones requeridas por el medio de pago seleccionado. En algunos
          casos puede que se requiera una verificación por medio de correo
          electrónico. Los precios de los productos ofrecidos en esta Tienda
          Online es válido solamente en las compras realizadas en este sitio
          web. LICENCIA Moom a través de su sitio web concede una licencia para
          que los usuarios utilicen los productos que son vendidos en este sitio
          web de acuerdo a los Términos y Condiciones que se describen en este
          documento. USO NO AUTORIZADO En caso de que aplique (para venta de
          software, templetes, u otro producto de diseño y programación) usted
          no puede colocar uno de nuestros productos, modificado o sin
          modificar, en un CD, sitio web o ningún otro medio y ofrecerlos para
          la redistribución o la reventa de ningún tipo. PROPIEDAD Usted no
          puede declarar propiedad intelectual o exclusiva a ninguno de nuestros
          productos, modificado o sin modificar. Todos los productos son
          propiedad de los proveedores del contenido. En caso de que no se
          especifique lo contrario, nuestros productos se proporcionan sin
          ningún tipo de garantía, expresa o implícita. En ningún esta compañía
          será responsables de ningún daño incluyendo, pero no limitado a, daños
          directos, indirectos, especiales, fortuitos o consecuentes u otras
          pérdidas resultantes del uso o de la imposibilidad de utilizar
          nuestros productos. POLÍTICA DE REEMBOLSO Y GARANTÍA En el caso de
          productos que sean mercancías irrevocables no-tangibles, no realizamos
          reembolsos después de que se envíe el producto, usted tiene la
          responsabilidad de entender antes de comprarlo. Le pedimos que lea
          cuidadosamente antes de comprarlo. Hacemos solamente excepciones con
          esta regla cuando la descripción no se ajusta al producto. Hay algunos
          productos que pudieran tener garantía y posibilidad de reembolso pero
          este será especificado al comprar el producto. En tales casos la
          garantía solo cubrirá fallas de fábrica y sólo se hará efectiva cuando
          el producto se haya usado correctamente. La garantía no cubre averías
          o daños ocasionados por uso indebido. Los términos de la garantía
          están asociados a fallas de fabricación y funcionamiento en
          condiciones normales de los productos y sólo se harán efectivos estos
          términos si el equipo ha sido usado correctamente. Esto incluye: – De
          acuerdo a las especificaciones técnicas indicadas para cada producto.
          – En condiciones ambientales acorde con las especificaciones indicadas
          por el fabricante. – En uso específico para la función con que fue
          diseñado de fábrica. – En condiciones de operación eléctricas acorde
          con las especificaciones y tolerancias indicadas. COMPROBACIÓN
          ANTIFRAUDE La compra del cliente puede ser aplazada para la
          comprobación antifraude. También puede ser suspendida por más tiempo
          para una investigación más rigurosa, para evitar transacciones
          fraudulentas. PRIVACIDAD Este moommaternidadonline.com garantiza que
          la información personal que usted envía cuenta con la seguridad
          necesaria. Los datos ingresados por usuario o en el caso de requerir
          una validación de los pedidos no serán entregados a terceros, salvo
          que deba ser revelada en cumplimiento a una orden judicial o
          requerimientos legales. La suscripción a boletines de correos
          electrónicos publicitarios es voluntaria y podría ser seleccionada al
          momento de crear su cuenta. Moom reserva los derechos de cambiar o de
          modificar estos términos sin previo aviso.
        </Typography>
      </Container>
      {/* Footer <- create component out of this footer*/}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </div>
  );
};

export default TermsAndConditions;
