/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      name
      description
      shortDesc
      lessons {
        items {
          id
          name
          description
          shortDesc
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          videoFile {
            bucket
            region
            key
          }
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      image {
        bucket
        region
        key
      }
      owner
      price
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      name
      description
      shortDesc
      lessons {
        items {
          id
          name
          description
          shortDesc
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          videoFile {
            bucket
            region
            key
          }
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      image {
        bucket
        region
        key
      }
      owner
      price
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      name
      description
      shortDesc
      lessons {
        items {
          id
          name
          description
          shortDesc
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          videoFile {
            bucket
            region
            key
          }
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      image {
        bucket
        region
        key
      }
      owner
      price
      createdAt
      updatedAt
    }
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson(
    $input: CreateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    createLesson(input: $input, condition: $condition) {
      id
      name
      description
      shortDesc
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      videoFile {
        bucket
        region
        key
      }
      seen
      createdAt
      updatedAt
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson(
    $input: UpdateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    updateLesson(input: $input, condition: $condition) {
      id
      name
      description
      shortDesc
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      videoFile {
        bucket
        region
        key
      }
      seen
      createdAt
      updatedAt
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson(
    $input: DeleteLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    deleteLesson(input: $input, condition: $condition) {
      id
      name
      description
      shortDesc
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      videoFile {
        bucket
        region
        key
      }
      seen
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      order {
        items {
          id
          user {
            id
            name
            createdAt
            updatedAt
          }
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      order {
        items {
          id
          user {
            id
            name
            createdAt
            updatedAt
          }
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      order {
        items {
          id
          user {
            id
            name
            createdAt
            updatedAt
          }
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      user {
        id
        name
        order {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      user {
        id
        name
        order {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      user {
        id
        name
        order {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
