import React from 'react'
import CourseList from "../components/CourseList";
import   NewCourse from "../components/NewCourse";

const Home = () =>{
return (
    <>
    <NewCourse/>
    <CourseList/>
    </>
)
}
export default Home;