import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import { PhotoPicker } from "aws-amplify-react";
import { makeStyles } from "@material-ui/core/styles";
import { createCourse } from "../graphql/mutations";
import { UserContext } from "./UserContext";
//import {  Form,Button,Dialog,Input,Select,Notification} from "element-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import aws_exports from "../aws-exports.js";

const NewCourse = ({}) => {
  const useStyles = makeStyles((theme) => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content",
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
  }));
  const classes = useStyles();
  const [dialog, setDialog] = useState(false);
  const [courseName, setCourseName] = useState(""); // useReducer for all course related info!
  const [shortDesc, setShortDesc] = useState("");
  const [courseDesc, setCourseDesc] = useState("");
  const [coursePrice, setCoursePrice] = useState(0);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [previewImage, setPreviewImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const userData = useContext(UserContext);

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };
  useEffect(() => {
    // Update the document title using the browser API
    {
      console.log(dialog);
      console.log(userData);
    }
  }, [userData]);
  const handleEditClick = () => {
    dialog === true ? setDialog(false) : setDialog(true);
  };

  const handleNameChange = (e) => {
    setCourseName(e.target.value);
  };
  const handleShortDescChange = (e) => {
    setShortDesc(e.target.value);
  };
  const handleCourseDescChange = (e) => {
    setCourseDesc(e.target.value);
  };
  const handleCoursePriceChange = (e) => {
    setCoursePrice(parseInt(e.target.value));
  };
  const handleAddCourse = async () => {
    try {
      console.log("course to be added: " + courseName);
      console.log("owner to be added: ", userData);
      const { identityId } = await Auth.currentCredentials();
      const filename = `/public/${identityId}/${Date.now()}-${imageFile.name}`;
      const uploadedFile = await Storage.put(filename, imageFile.file, {
        level: "public",
        contentType: imageFile.type,
      });
      const file = {
        key: uploadedFile.key,
        bucket: aws_exports.aws_user_files_s3_bucket,
        region: aws_exports.aws_user_files_s3_bucket_region,
      };
      const input = {
        name: courseName,
        image: file,
        owner: userData.id,
        shortDesc: shortDesc,
        description: courseDesc,
        price: coursePrice,
      };
      const result = await API.graphql(
        graphqlOperation(createCourse, { input })
      );
      console.log(`added course with id ${result.data.createCourse.id}`);
      setDialog(false);
      setCourseName("");
      setShortDesc("");
      setCourseDesc("");
      setPreviewImage("");
      setImageFile(null);
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    setDialog(false);
  };
  return (
    <>
      <div className="course-header"></div>
      {userData.admin && (
        <h1 className="course-title mw6 center bg-light-gray pa3 flex flex-row justify-around">
          Create your course{" "}
          {/* create a loading variable to wait for userdata info, then check the ~payload.cognito.groups[], if an admin then allow this, also modify schema to match*/}
          <Button variant="contained" color="primary" onClick={handleEditClick}>
            create
          </Button>
        </h1>
      )}
      <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Course</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please type your new course name
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="course name"
            type="text"
            fullWidth
            onChange={handleNameChange}
          />
          <DialogContentText>
            Please type a short description for your course
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="shortDesc"
            label="Short Description"
            type="text"
            fullWidth
            onChange={handleShortDescChange}
          />
          <DialogContentText>
            Please type a description for your course
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="courseDesc"
            label="Course Description"
            type="text"
            fullWidth
            onChange={handleCourseDescChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="coursePrice"
            label="Precio $ 0 (sin centavos)"
            type="text"
            fullWidth
            onChange={handleCoursePriceChange}
          />
          {previewImage && (
            <img
              className="image-preview"
              src={previewImage}
              alt="course preview image"
            />
          )}
          <PhotoPicker
            preview="hidden"
            onLoad={(previewImage) => {
              setPreviewImage(previewImage);
            }}
            onPick={(imageFile) => {
              setImageFile(imageFile);
              console.log(imageFile);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddCourse} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default NewCourse;
