import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import { PhotoPicker } from "aws-amplify-react";
import { createLesson } from "../graphql/mutations";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  CircularProgress,
  Typography,
  Box,
} from "@material-ui/core";
import aws_exports from "../aws-exports.js";
import CheckIcon from "@material-ui/icons/Check";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const NewLesson = ({ course, user }) => {
  const [dialog, setDialog] = useState(false);
  const [lessonName, setLessonName] = useState({});
  const [description, setDescription] = useState("");
  const [videoFile, setvideoFile] = useState(null);
  const [progressBar, setProgressBar] = useState(0);
  const isUserAuthor = user && user.attributes.sub === course.owner;

  const handleClose = () => {
    setDialog(false);
  };

  const handleEditClick = () => {
    dialog === true ? setDialog(false) : setDialog(true);
  };

  const handleNameChange = (e) => {
    setLessonName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleAddLesson = async () => {
    console.log("lesson to be added: " + lessonName);
    const { identityId } = await Auth.currentCredentials();
    console.log("id: " + identityId);
    const filename = `/public/${identityId}/${Date.now()}-${videoFile.name}`;
    const uploadedFile = await Storage.put(filename, videoFile.file, {
      level: "public",
      contentType: videoFile.type,
      progressCallback: (progress) => {
        const totalAvg = (progress.loaded / progress.total) * 100;
        console.log(`progress: ${totalAvg}`);
        setProgressBar(totalAvg);
      },
    });
    const file = {
      key: uploadedFile.key,
      bucket: aws_exports.aws_user_files_s3_bucket,
      region: aws_exports.aws_user_files_s3_bucket_region,
    };
    console.log("file to be added: " + file);
    try {
      console.log("Lesson to be added: " + lessonName);
      const input = {
        name: lessonName,
        description,
        lessonCourseId: course.id,
        videoFile: file,
      };
      const result = await API.graphql(
        graphqlOperation(createLesson, { input })
      );
      console.log(`added Lesson with id ${result.data.createLesson.id}`);
      setDialog(false);
      setLessonName("");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {console.log(`course: ${course.owner}, user: ${user.attributes.sub}`)}
      {isUserAuthor && (
        <Button variant="contained" color="primary" onClick={handleEditClick}>
          Create lesson
        </Button>
      )}

      <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add new lesson</DialogTitle>
        <DialogContent>
          <DialogContentText>Add a new lesson</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="lname"
            label="Lesson name"
            type="text"
            fullWidth
            onChange={handleNameChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="lname"
            label="Lesson description"
            type="text"
            fullWidth
            onChange={handleDescriptionChange}
          />
          {progressBar > 0 ? (
            <span>
              <h3>Uploading...</h3>
              <CircularProgressWithLabel value={progressBar} />
            </span>
          ) : (
            <PhotoPicker
              preview="hidden"
              onPick={(videoFile) => {
                setvideoFile(videoFile);
                console.log(videoFile);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {progressBar === 100 ? (
            <CheckIcon />
          ) : progressBar > 0 ? null : (
            <Button onClick={handleAddLesson} color="primary">
              Create lesson
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewLesson;
