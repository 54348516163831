import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import StarIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/core/styles";
import Amplify, { Auth } from "aws-amplify";
import aws_exports from "../aws-exports.js";
import companyLogo from "../components/logo.png";
import "./landing.css";
Amplify.configure(aws_exports);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mooommaternidadonline.com/">
        Moom
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
}));

const tiers = [
  {
    title: "Free",
    price: "0",
    description: [
      "10 users included",
      "2 GB of storage",
      "Help center access",
      "Email support",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
  },
  {
    title: "Pro",
    subheader: "Most popular",
    price: "15",
    description: [
      "20 users included",
      "10 GB of storage",
      "Help center access",
      "Priority email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    title: "Enterprise",
    price: "30",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
];

const footers = [
  {
    title: "Moom",
    description: ["Servicios Moom", "Testimonios", "Contáctanos"],
    mappedComponents: [
      "https://www.moommaternidadonline.com/#Service",
      "https://www.moommaternidadonline.com/#Testimonials",
      "http://m.me/moommaternidadonline",
    ],
  },
  {
    title: "Recursos",
    description: ["Blog", "Canal Youtube", "Facebook", "Instagram"],
    mappedComponents: [
      "https://www.moommaternidadonline.com/#Blogs",
      "https://www.youtube.com/channel/UCKlKE8jqNC86EqjnQSXCPyQ",
      "https://www.facebook.com/moommaternidadonline/",
      "https://www.instagram.com/moom.maternidad/",
    ],
  },
  {
    title: "Legal",
    description: ["Política de privacidad", "Términos de uso"],
    mappedComponents: [
      "https://app.moommaternidadonline.com/privacy",
      "https://app.moommaternidadonline.com/terms/",
    ],
  },
];

const Landing = () => {
  const classes = useStyles();
  return (
    <div className="App">
      {" "}
      {/* create component for navigation/app bar*/}
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.toolbarTitle}
          >
            <img
              src={companyLogo}
              style={{ maxWidth: "50px" }}
              alt="company logo"
            />
          </Typography>
          <nav>
            <Link
              variant="button"
              color="textPrimary"
              href="http://m.me/moommaternidadonline"
              className={classes.link}
            >
              Contacto
            </Link>
          </nav>
          <Button
            color="primary"
            variant="outlined"
            className={classes.link}
            onClick={() => Auth.federatedSignIn()}
          >
            Entrar
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Moom
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          Descubre nuestra nueva plataforma donde encontrarás cursos y una vez
          comprado visualizarlo cuando quieras!
        </Typography>
      </Container>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Grid container direction="row" justify="center">
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Crianza y maternidad online!
            </Typography>
            <div class="video-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fkzabXmLz0E"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* Footer <- create component out of this footer*/}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item, idx) => (
                  <li key={item}>
                    <Link
                      variant="subtitle1"
                      color="textSecondary"
                      style={{ textDecoration: "none" }}
                      href={`${footer.mappedComponents[idx]}`}
                    >
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </div>
  );
};

export default Landing;
