/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b71c4ada-7c11-4fd2-b8c3-c70955a7c243",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_kMM0IVl0v",
    "aws_user_pools_web_client_id": "6thu01vonnvvk4jpoqdk1g0slu",
    "oauth": {
        "domain": "moomonlinef01d86d7-f01d86d7-pdt.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.moommaternidadonline.com/",
        "redirectSignOut": "https://app.moommaternidadonline.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://vspkpmymobc3fi2r45wkogqcia.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "moomonline23f6b4cda9b440d9a6d4faed739cc989110821-pdt",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
