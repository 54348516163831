import React, { useState, useEffect } from "react";
import { Menu as Nav, Icon, Button } from "element-react";
import { NavLink } from "react-router-dom";
import companyLogo from "./logo.png";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import HomeIcon from "@material-ui/icons/Home";

const NavBar = ({ user, handleSignout }) => {
  const [menuSt, setMenu] = useState(false);
  const [dialog, setDialog] = useState(false);
  const handleMenu = (event) => {
    setMenu(event.currentTarget);
    dialog === true ? setDialog(false) : setDialog(true);
    console.log(`dialog: ${dialog}`);
  };
  const handleClose = () => {
    setMenu(null);
    setDialog(false);
  };
  useEffect(() => {
    // Update the document title using the browser API
    console.log(`user: ${user}`);
  }, []);

  const handleLogout = () => {
    setMenu(null);
    setDialog(false);
  };

  const handleEditClick = () => {
    dialog === true ? setDialog(false) : setDialog(true);
  };

  return (
    <AppBar style={{ backgroundColor: "#00CCCC" }} position="static">
      <Toolbar className="flex flex-row justify-around">
        <Link style={{ textDecoration: "none" }} to={`/`}>
          <img
            src={companyLogo}
            style={{ maxWidth: "50px" }}
            alt="company logo"
          />
          {/* <IconButton aria-label="Menu">
            <HomeIcon />
          </IconButton> */}
        </Link>
        <Typography variant="title" color="inherit">
          Hola {user.signInUserSession.idToken.payload.given_name} !
        </Typography>
        <div>
          <IconButton
            aria-owns={dialog ? "menu-appbar" : null}
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={menuSt}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={dialog}
            onClose={handleClose}
          >
            {/* <Link style={{ textDecoration: "none" }} to={`/`}>
              <MenuItem onClick={handleClose}>Home</MenuItem>
            </Link> */}
            <Link style={{ textDecoration: "none" }} to={`/profile`}>
              <MenuItem onClick={handleClose}>Profile</MenuItem>
            </Link>
            <MenuItem
              style={{ textDecoration: "none" }}
              onClick={handleSignout}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;
