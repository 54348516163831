/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse {
    onCreateCourse {
      id
      name
      description
      shortDesc
      lessons {
        items {
          id
          name
          description
          shortDesc
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          videoFile {
            bucket
            region
            key
          }
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      image {
        bucket
        region
        key
      }
      owner
      price
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse {
    onUpdateCourse {
      id
      name
      description
      shortDesc
      lessons {
        items {
          id
          name
          description
          shortDesc
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          videoFile {
            bucket
            region
            key
          }
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      image {
        bucket
        region
        key
      }
      owner
      price
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse {
    onDeleteCourse {
      id
      name
      description
      shortDesc
      lessons {
        items {
          id
          name
          description
          shortDesc
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          videoFile {
            bucket
            region
            key
          }
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      image {
        bucket
        region
        key
      }
      owner
      price
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLesson = /* GraphQL */ `
  subscription OnCreateLesson {
    onCreateLesson {
      id
      name
      description
      shortDesc
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      videoFile {
        bucket
        region
        key
      }
      seen
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLesson = /* GraphQL */ `
  subscription OnUpdateLesson {
    onUpdateLesson {
      id
      name
      description
      shortDesc
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      videoFile {
        bucket
        region
        key
      }
      seen
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLesson = /* GraphQL */ `
  subscription OnDeleteLesson {
    onDeleteLesson {
      id
      name
      description
      shortDesc
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      videoFile {
        bucket
        region
        key
      }
      seen
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      order {
        items {
          id
          user {
            id
            name
            createdAt
            updatedAt
          }
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      order {
        items {
          id
          user {
            id
            name
            createdAt
            updatedAt
          }
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      order {
        items {
          id
          user {
            id
            name
            createdAt
            updatedAt
          }
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      user {
        id
        name
        order {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      user {
        id
        name
        order {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      user {
        id
        name
        order {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
