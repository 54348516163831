import React, { useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createCharge } from "../graphql/queries";
import StripeCheckout from "react-stripe-checkout";
import { createOrder } from "../graphql/mutations";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Analytics } from "aws-amplify";
import { history } from "../App";
import ReactPixel from "react-facebook-pixel";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init("2590056854592825", advancedMatching, options);

const stripeConfig = {
  currency: "MXN",
  pKey:
    process.env.NODE_ENV == "production"
      ? "pk_live_51HZou6JWka6DEbdhNQLnBap6A2phiKA438EqPk9Yg0au2NpucMg8h99FzGiuDZAwUnzUbPoqxbmCeG7M981nNNuR00sFWzQ6Nx"
      : "pk_test_51HNttKFomFV2Xazi5QCWBSQUb2Kw0QP1cfHBwfcETdYMj1iTfOePt6mjvZH1LF4Qzbe5Wkyq6WxewmAj6XYeEhux00r3fTALeB",
};
const PayButton = ({ product, user }) => {
  const [open, setOpen] = React.useState(false);
  const [openErr, setOpenErr] = React.useState(false);
  const [parsedResponse, setParsedResponse] = React.useState(false);

  const logEvent = async () => {
    console.log("updateEndpoint");
    await Analytics.updateEndpoint({
      address: user.email,
      attributes: {
        purchased: ["yes"],
      },
      channelType: "EMAIL",
      optOut: "NONE",
      userAttributes: {
        username: [user.given_name],
      },
      userId: user.email,
    });
    await Analytics.record({ name: "checkout" });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      // log not bought
      return;
    } else {
      console.log("timeout");
    }
    history.push(`/courses/${product.id}`);
    setOpen(false);
  };
  const handleCloseErr = (event, reason) => {
    if (reason === "clickaway") {
      // log not bought
      return;
    } else {
      console.log("timeout");
    }
    console.error("payment failed");
    setOpenErr(false);
  };

  const handleOnToken = async (token) => {
    console.log("hello token", token);
    let result = null;
    if (user.id) {
      try {
        result = await API.graphql(
          graphqlOperation(createCharge, {
            tokenId: token.id,
            amount: product.price,
            description: product.shortDesc,
          })
        );
        logEvent();
        console.log(result);
      } catch (error) {
        result = error;
        console.error("error: : ", result);
      }
      console.log("user: ", user);
    } else {
      console.error("empty user id!");
    }
    var parsedJSON = JSON.parse(result.data.createCharge);
    setParsedResponse(parsedJSON);
    if (parsedJSON.rc == true) {
      console.log("user: ", user);
      // create order : update user with new course...userId?
      const input = {
        orderCourseId: product.id,
        orderUserId: user.id,
        // to be fixed, this is hardcoded, instead we should get the array containing all existing courses, spread them and then add the new one
      };
      if (input.orderUserId && user.id) {
        try {
          result = await API.graphql(graphqlOperation(createOrder, { input }));
          console.log(result);
          setOpen(true);
        } catch (error) {
          result = error;
          console.error(result);
        }
        //add thank you or conversion complete event
        ReactPixel.track("Purchase", { currency: "MXN", value: product.price }); // will need to be condition to staging: only valid on PROD
      } else {
        console.error(
          "cannot create an order without userId to link to the order!"
        );
      }
      // userData.id
    } else {
      setOpenErr(true);
      console.error("failed to create payment");
    }
  };
  return (
    <>
      {user.id && (
        <StripeCheckout
          token={handleOnToken}
          name={product.name}
          amount={product.price * 100}
          currency={stripeConfig.currency}
          stripeKey={stripeConfig.pKey}
          label="Pagar con tarjeta"
          locale="es"
        ></StripeCheckout>
      )}
      <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Felicidades, has comprado este curso!
        </Alert>
      </Snackbar>
      <Snackbar open={openErr} autoHideDuration={3500} onClose={handleCloseErr}>
        <Alert onClose={handleCloseErr} severity="error">
          Hubo un error con tu pago: {`${parsedResponse.message}`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PayButton;
