import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getCourse, listCourses, listLessons } from "../graphql/queries";
import { deleteLesson, updateLesson } from "../graphql/mutations";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Button,
} from "@material-ui/core";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import NewLesson from "../components/NewLesson";
import ReactPlayer from "react-player";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Collapse } from "react-collapse";
import Checkbox from "@material-ui/core/Checkbox";
import createBrowserHistory from "history/createBrowserHistory";
import { UserContext } from "../components/UserContext";
const history = createBrowserHistory();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const useListStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Course = ({ courseId, userCognito }) => {
  const [course, setCourse] = useState();
  const [bucket, setBucket] = useState();
  const [prUrl, setPresigned] = useState([]);
  const [lesson, setLesson] = useState({});
  const [sortedLessons, setSortedLessons] = useState([]);
  const [curIdx, setIdx] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [isOpened, setOpened] = useState(false);
  const [isDescOpened, setDescOpened] = useState(true);
  const isUserAuthor =
    userCognito && userCognito.attributes.sub === course?.owner;
  const userGraphQl = useContext(UserContext);

  const classes = useStyles();
  const listclasses = useListStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    {
      !course && getCourses();
    }
  }, [course, lesson]);
  const getCourses = async () => {
    const input = {
      id: courseId,
    };
    try {
      const result = await API.graphql(graphqlOperation(getCourse, input));
      const newCourse = {
        ...result.data.getCourse,
      };
      console.error(newCourse);
      setCourse(newCourse);
      const coursesList = sortByDate(newCourse?.lessons?.items);
      setSortedLessons(coursesList);
      console.log("getcourses, courseslits: ", coursesList);
      setLesson({ ...coursesList[curIdx] });
      getLessons(coursesList);
      //setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getLessons = (courseLesson) => {
    setLoading(false);
    getIndividualUrls(courseLesson);
  };
  const getIndividualUrls = (lessons) => {
    lessons.map((individualLesson) => {
      console.log(individualLesson);
      return getUrl(
        individualLesson.videoFile.bucket,
        individualLesson.videoFile.key
      );
    });
  };
  const getUrl = async (bucket, key) => {
    const presignedUrl = await Storage.get(key);
    const newprUrl = [...prUrl, presignedUrl];
    console.log(newprUrl);
    setPresigned((prevState) => {
      return [...prevState, presignedUrl];
    });
    console.log(prUrl);
    console.log(`bucket: ${bucket}, key: ${key}`);
    console.log("presignedUrl: " + presignedUrl);
  };

  const handleDeleteLesson = async (lessonId) => {
    console.log(`deleting lesson: ${lessonId}`);
    console.log(lessonId);
    const input = {
      id: lessonId,
    };
    console.log(courseId);
    try {
      const result = await API.graphql(
        graphqlOperation(deleteLesson, { input })
      );
      console.log(result);
      if (curIdx > 0) {
        setIdx(curIdx - 1);
        setLesson({
          ...sortedLessons[curIdx - 1],
        });
      } else {
        setIdx(curIdx + 1);
        setLesson({
          ...sortedLessons[curIdx + 1],
        });
      }
      setLesson(curIdx);
      // const newCourse = {
      //         ...result.data.getCourse
      // }
      // console.log(result);
      // setCourse(newCourse);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleCollapse = () => {
    setOpened((prevState) => {
      if (prevState) {
        console.log("collapsing");
        return false;
      } else {
        console.log("uncollapsing");
        return true;
      }
    });
  };
  const toggleDescCollapse = () => {
    setDescOpened((prevState) => {
      if (prevState) {
        console.log("collapsing");
        return false;
      } else {
        console.log("uncollapsing");
        return true;
      }
    });
  };
  const sortByDate = (courseList) => {
    let needsSort = 1;
    let sortedList = [...courseList];
    console.log("sortByDate >", sortedList);
    for (let j = 0; j < sortedList.length - 1; j++) {
      for (let i = 0; i < sortedList.length - j - 1; i++) {
        if (sortedList[i].createdAt > sortedList[i + 1].createdAt) {
          const tmpBuffer = sortedList[i];
          sortedList[i] = sortedList[i + 1];
          sortedList[i + 1] = tmpBuffer;
        }
      }
    }

    console.log("sortByDate <", sortedList);
    return sortedList;
  };

  const endLesson = async () => {
    console.log("lesson ended");
    let input = {
      id: lesson.id,
      seen: true,
    };

    const result = await API.graphql(graphqlOperation(updateLesson, { input }));
    console.log("result update lesson: ", result);
    if (result) {
      curIdx < course?.lessons?.items.length - 1 && setIdx(curIdx + 1);
      getCourses();
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid container justify="center">
          <Grid item xs={6} sm={3}>
            <h1>{isLoading ? "Nombre del curso" : course.name}</h1>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={6} sm={3}>
            <Collapse isOpened={isDescOpened}>
              <p>{isLoading ? "Descripción del curso" : course.description}</p>
            </Collapse>
            {!isDescOpened && (
              <ArrowDropDownIcon
                fontSize="large"
                onClick={toggleDescCollapse}
              />
            )}
            {isDescOpened && (
              <ExpandLessIcon fontSize="large" onClick={toggleDescCollapse} />
            )}
          </Grid>
        </Grid>
      </Grid>
      {/*get a list of videos and iterate*/}
      {
        // course?.lessons?.items?.map((item, idx) => {
        !isLoading && lesson && (
          <>
            <Grid container direction="column" spacing={2}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={6} sm={3}>
                  <h2>{lesson.name}</h2>
                </Grid>
                <Grid container alignItems="center" direction="column">
                  <Grid item xs={12} sm={12} md={6}>
                    {
                      <Card>
                        <CardActionArea>
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            url={prUrl[curIdx]}
                            playing={true}
                            onEnded={endLesson}
                            controls={true}
                            config={{
                              file: {
                                attributes: {
                                  onContextMenu: (e) => e.preventDefault(),
                                  controlsList: "nodownload ",
                                },
                              },
                            }}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="headline"
                              component="h3"
                            >
                              {lesson.name}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="headline"
                              component="p"
                            >
                              {lesson.description}
                            </Typography>
                            <Typography>{lesson.shortDesc}</Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div>
                              <IconButton
                                aria-label="Previous"
                                onClick={() => {
                                  if (curIdx > 0) {
                                    const tpmCurIdx = curIdx;
                                    setIdx(curIdx - 1);
                                    setLesson({
                                      ...sortedLessons[tpmCurIdx - 1],
                                    });
                                  }
                                }}
                              >
                                <SkipPreviousIcon />
                              </IconButton>
                              <IconButton
                                aria-label="Next"
                                onClick={() => {
                                  if (
                                    curIdx <
                                    course?.lessons?.items.length - 1
                                  ) {
                                    const tpmCurIdx = curIdx;
                                    setIdx(curIdx + 1);
                                    setLesson({
                                      ...sortedLessons[tpmCurIdx + 1],
                                    });
                                  }
                                }}
                              >
                                <SkipNextIcon />
                              </IconButton>
                              <div style={{ alignSelf: "flex-start" }}>
                                <PlaylistPlayIcon
                                  fontSize="large"
                                  onClick={toggleCollapse}
                                />
                                <Collapse isOpened={isOpened}>
                                  <List
                                    component="nav"
                                    className={listclasses.root}
                                    aria-label="contacts"
                                  >
                                    {sortedLessons.map((llesson, idx) => {
                                      console.log("this is llesson:", llesson);
                                      return (
                                        <ListItem
                                          style={{
                                            display: "flex",
                                            justify: "space-between",
                                          }}
                                          button
                                          onClick={() => {
                                            setIdx(idx);
                                            setLesson({
                                              ...sortedLessons[idx],
                                            });
                                          }}
                                        >
                                          {idx === curIdx && (
                                            <ListItemIcon>
                                              <ArrowForwardIcon />
                                            </ListItemIcon>
                                          )}
                                          <ListItemText
                                            inset
                                            primary={llesson.name}
                                          />
                                          <Checkbox
                                            checked={llesson.seen}
                                            onClick={async () => {
                                              if (llesson.seen) {
                                                let input = {
                                                  id: llesson.id,
                                                  seen: false,
                                                };
                                                const result =
                                                  await API.graphql(
                                                    graphqlOperation(
                                                      updateLesson,
                                                      { input }
                                                    )
                                                  );
                                                if (result) {
                                                  getCourses();
                                                }
                                                console.log(result);
                                              }
                                            }}
                                            inputProps={{
                                              "aria-label": "primary checkbox",
                                            }}
                                          />
                                          <div>
                                            {isUserAuthor && (
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={(e) =>
                                                  handleDeleteLesson(lesson.id)
                                                }
                                                style={{ padding: "0px" }}
                                              >
                                                Delete
                                              </Button>
                                            )}
                                          </div>
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                </Collapse>
                              </div>
                            </div>
                          </div>
                        </CardActions>
                      </Card>
                    }
                    {/* </Collapse> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
      {!isLoading && <NewLesson course={course} user={userCognito} />}
    </>
  );
};
export default Course;
