import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import StarIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/core/styles";
import Amplify, { Auth } from "aws-amplify";
import aws_exports from "../aws-exports.js";
import companyLogo from "../components/logo.png";
Amplify.configure(aws_exports);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mooommaternidadonline.com/">
        Moom
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
}));

const tiers = [
  {
    title: "Free",
    price: "0",
    description: [
      "10 users included",
      "2 GB of storage",
      "Help center access",
      "Email support",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
  },
  {
    title: "Pro",
    subheader: "Most popular",
    price: "15",
    description: [
      "20 users included",
      "10 GB of storage",
      "Help center access",
      "Priority email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    title: "Enterprise",
    price: "30",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
];

const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Features",
    description: [
      "Cool stuff",
      "Random feature",
      "Team feature",
      "Developer stuff",
      "Another one",
    ],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <div className="App">
      {" "}
      {/* create component for navigation/app bar*/}
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.toolbarTitle}
          >
            <img
              src={companyLogo}
              style={{ maxWidth: "50px" }}
              alt="company logo"
            />
          </Typography>
          <nav>
            <Link
              variant="button"
              color="textPrimary"
              href="http://m.me/moommaternidadonline"
              className={classes.link}
            >
              Contacto
            </Link>
          </nav>
          <Button
            color="primary"
            variant="outlined"
            className={classes.link}
            onClick={() => Auth.federatedSignIn()}
          >
            Entrar
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Aviso de Privacidad
        </Typography>
        <Typography
          variant="p"
          align="center"
          color="textSecondary"
          component="p"
        >
          Aviso de privacidad integral Moom maternidad online mejor conocido
          como Moom, con domicilio en circuito canarios #133, Zibatá y portal de
          internet www.moommaternidadonline.com, es el responsable del uso y
          protección de sus datos personales, y al respecto le informamos lo
          siguiente: ¿Para qué fines utilizaremos sus datos personales? Los
          datos personales que recabamos de usted, los utilizaremos para las
          siguientes finalidades que son necesarias para el servicio que
          solicita: Prestación de cualquier servicio solicitado, Envío de
          productos adquiridos en esta tienda en línea notificar de avisos y
          cambios ¿Qué datos personales utilizaremos para estos fines? Para
          llevar a cabo las finalidades descritas en el presente aviso de
          privacidad, utilizaremos los siguientes datos personales: Datos de
          identificación y contacto ¿Cómo puede acceder, rectificar o cancelar
          sus datos personales, u oponerse a su uso o ejercer la revocación de
          consentimiento? Usted tiene derecho a conocer qué datos personales
          tenemos de usted, para qué los utilizamos y las condiciones del uso
          que les damos (Acceso). Asimismo, es su derecho solicitar la
          corrección de su información personal en caso de que esté
          desactualizada, sea inexacta o incompleta (Rectificación); que la
          eliminemos de nuestros registros o bases de datos cuando considere que
          la misma no está siendo utilizada adecuadamente (Cancelación); así
          como oponerse al uso de sus datos personales para fines específicos
          (Oposición). Estos derechos se conocen como derechos ARCO. Para el
          ejercicio de cualquiera de los derechos ARCO, debe enviar una petición
          vía correo electrónico a Moom.maternidadonline@gmail.com y deberá
          contener: Nombre completo del titular. Domicilio. Teléfono. Correo
          electrónico usado en este sitio web. Copia de una identificación
          oficial adjunta. Asunto “Derechos ARCO” Descripción el objeto del
          escrito, los cuales pueden ser de manera enunciativa más no limitativa
          los siguientes: Revocación del consentimiento para tratar sus datos
          personales; y/o Notificación del uso indebido del tratamiento de sus
          datos personales; y/o Ejercitar sus Derechos ARCO, con una descripción
          clara y precisa de los datos a Acceder, Rectificar, Cancelar o bien,
          Oponerse. En caso de Rectificación de datos personales, deberá indicar
          la modificación exacta y anexar la documentación soporte; es
          importante en caso de revocación del consentimiento, que tenga en
          cuenta que no en todos los casos podremos atender su solicitud o
          concluir el uso de forma inmediata, ya que es posible que por alguna
          obligación legal requiramos seguir tratando sus datos personales.
          Asimismo, usted deberá considerar que para ciertos fines, la
          revocación de su consentimiento implicará que no le podamos seguir
          prestando el servicio que nos solicitó, o la conclusión de su relación
          con nosotros. ¿En cuántos días le daremos respuesta a su solicitud? 10
          días d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?
          Al mismo correo electrónico de donde se envío la petición. El uso de
          tecnologías de rastreo en nuestro portal de internet Le informamos que
          en nuestra página de internet utilizamos cookies, web beacons u otras
          tecnologías, a través de las cuales es posible monitorear su
          comportamiento como usuario de internet, así como brindarle un mejor
          servicio y experiencia al navegar en nuestra página. Los datos
          personales que obtenemos de estas tecnologías de rastreo son los
          siguientes: Identificadores, nombre de usuario y contraseñas de
          sesión, Fecha y hora del inicio y final de una sesión de un usuario,
          Páginas web visitadas por un usuario Estas cookies, web beacons y
          otras tecnologías pueden ser deshabilitadas. Para conocer cómo
          hacerlo, consulte el menú de ayuda de su navegador. Tenga en cuenta
          que, en caso de desactivar las cookies, es posible que no pueda
          acceder a ciertas funciones personalizadas en nuestros sitio web.
          ¿Cómo puede conocer los cambios en este aviso de privacidad? El
          presente aviso de privacidad puede sufrir modificaciones, cambios o
          actualizaciones derivadas de nuevos requerimientos legales; de
          nuestras propias necesidades por los productos o servicios que
          ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro
          modelo de negocio, o por otras causas. Nos comprometemos a mantener
          actualizado este aviso de privacidad sobre los cambios que pueda
          sufrir y siempre podrá consultar las actualizaciones que existan en el
          sitio web www.moommaternidadonline.com. Última actualización de este
          aviso de privacidad: 10/03/2021
        </Typography>
      </Container>
      {/* Footer <- create component out of this footer*/}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </div>
  );
};

export default PrivacyPolicy;
