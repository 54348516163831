import { Grid } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../components/UserContext";
const Profile = () => {
  const userData = useContext(UserContext);
  useEffect(() => {
    // Update the document title using the browser API
    {
      console.log(userData);
    }
  }, [userData]);
  return (
    <>
      <Grid container direction="column" alignItems="center" spacing={3}>
        <h1>Profile</h1>
        {userData && (
          <img
            style={{ borderRadius: "50%", height: "100px" }}
            src={userData.picture}
          ></img>
        )}
        {userData && <h2>{userData.name}</h2>}
        {userData && <h4>{userData.given_name}</h4>}
      </Grid>
    </>
  );
};

export default Profile;
