import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { S3Image } from "aws-amplify-react";
import { listCourses } from "../graphql/queries";
import { onCreateCourse } from "../graphql/subscriptions";
import { Link } from "react-router-dom";
import PayButton from "./PayButton";
import { UserContext } from "./UserContext";

import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const CourseList = ({}) => {
  const [cursos, setCursos] = useState(null);
  const [user, setUser] = useState(null);
  const userData = useContext(UserContext);

  const fetchCourses = async () => {
    if (!cursos) {
      try {
        const result = await API.graphql(graphqlOperation(listCourses));
        console.log(result);
        setCursos(result.data.listCourses.items);
        console.log(`read courses with id ${result.data.listCourses}`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const suscribeCoursesChanges = async () => {
    const result = await API.graphql(
      graphqlOperation(onCreateCourse)
    ).subscribe({
      next: (createdCourse) => {
        console.log(createdCourse.value.data.onCreateCourse);
        const nuevoCurso = createdCourse.value.data.onCreateCourse.name;
        setCursos((prevCursos) => {
          const updatedCursos = [
            ...(prevCursos != null ? prevCursos : []),
            nuevoCurso,
          ];
          return updatedCursos;
        });
      },
    });
  };
  const isCourseBought = (curso) => {
    let bought = false;
    console.log("isCourseBought : userdata: ", userData);
    if (userData && userData.order) {
      for (let i = 0; i < userData.order.items.length; i++) {
        if (userData.order.items[i].course?.id === curso.id) {
          return true;
        }
      }
    }
    console.log("bought default: ", bought);
    return bought;
  };

  useEffect(() => {
    {
      fetchCourses();
      suscribeCoursesChanges();
      console.log(userData);
    }
  }, [userData, cursos]);
  return (
    <>
      <h1 className="course-title mw8 center bg-light-gray pa3 flex flex-row justify-around">
        Cursos
      </h1>
      <div className="mw8 center bg-light-gray pa3 flex flex-column justify-around items-center">
        <Grid container direction="column" alignItems="center" spacing={3}>
          {cursos?.length > 0 ? (
            cursos.map((curso) => {
              return (
                <>
                  <Grid item xs={12} sm={12}>
                    <Card>
                      <CardActionArea>
                        <S3Image imgKey={curso.image?.key} />
                        <CardMedia title="cerebro reptiliano" />
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="headline"
                            component="h2"
                          >
                            {curso.name}
                          </Typography>
                          <Typography>{curso.shortDesc}</Typography>
                          <Typography>{`Precio: $ ${curso.price}`}</Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        {!isCourseBought(curso) &&
                        curso.owner != userData.id ? (
                          <PayButton product={curso} user={userData}>
                            Comprar
                          </PayButton>
                        ) : (
                          <Link to={`/courses/${curso.id}`}>
                            <Button size="small" color="primary">
                              Ir al contenido del curso
                            </Button>
                          </Link>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                  <div className="flex flex-column justify-center flex-grow"></div>
                </>
              );
            })
          ) : (
            <h2>no hay cursos</h2>
          )}
        </Grid>
      </div>
    </>
  );
};
export default CourseList;
