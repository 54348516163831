/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCharge = /* GraphQL */ `
  query CreateCharge($tokenId: String, $amount: Int, $description: String) {
    createCharge(tokenId: $tokenId, amount: $amount, description: $description)
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      description
      shortDesc
      lessons {
        items {
          id
          name
          description
          shortDesc
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          videoFile {
            bucket
            region
            key
          }
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      image {
        bucket
        region
        key
      }
      owner
      price
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLesson = /* GraphQL */ `
  query GetLesson($id: ID!) {
    getLesson(id: $id) {
      id
      name
      description
      shortDesc
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      videoFile {
        bucket
        region
        key
      }
      seen
      createdAt
      updatedAt
    }
  }
`;
export const listLessons = /* GraphQL */ `
  query ListLessons(
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        shortDesc
        course {
          id
          name
          description
          shortDesc
          lessons {
            nextToken
          }
          image {
            bucket
            region
            key
          }
          owner
          price
          createdAt
          updatedAt
        }
        videoFile {
          bucket
          region
          key
        }
        seen
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      order {
        items {
          id
          user {
            id
            name
            createdAt
            updatedAt
          }
          course {
            id
            name
            description
            shortDesc
            owner
            price
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      user {
        id
        name
        order {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        name
        description
        shortDesc
        lessons {
          items {
            id
            name
            description
            shortDesc
            seen
            createdAt
            updatedAt
          }
          nextToken
        }
        image {
          bucket
          region
          key
        }
        owner
        price
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          name
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        course {
          id
          name
          description
          shortDesc
          lessons {
            nextToken
          }
          image {
            bucket
            region
            key
          }
          owner
          price
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
